import React from 'react';
import Routes from 'routes';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import { Loader } from 'components';
import { connect } from 'react-redux';
import { checkUserAuth } from 'store/actions/Auth';
import { useMountEffect } from 'hooks/useMountEffect';
import Modal from './components/common/Modal';

const App = ({ checkUserAuth, appInitialized }) => {
    useMountEffect(checkUserAuth);

    if (!appInitialized) {
        return <Loader />;
    }

    return (
        <>
            <Notistack>
                <Routes />
                <Modal />
                <Snackbar />
            </Notistack>
        </>
    );
};

const mapStateToProps = ({ init }) => {
    const { appInitialized } = init;
    return { appInitialized };
};

export default connect(mapStateToProps, { checkUserAuth })(App);
