import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideModal } from 'store/slices/modalSlice';
import Dialog from '@mui/material/Dialog';
import { PopupTransition } from '../@extended/Transitions';

const Modal = () => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modal.modals);

    const handleClose = id => {
        dispatch(hideModal({ id }));
    };

    return (
        <>
            {modals.map(({ id, content: Content, props, modalContainerProps }) => (
                <Dialog
                    key={id}
                    open={true}
                    onClose={() => handleClose(id)}
                    TransitionComponent={PopupTransition}
                    keepMounted
                    sx={{
                        '& .MuiDialog-paper': { p: 0 },
                        transition: 'transform 225ms'
                    }}
                    aria-describedby="alert-dialog-slide-description"
                    maxWidth={modalContainerProps.maxWidth || 'sm'}
                    fullWidth={modalContainerProps.fullWidth || false}
                >
                    {Content && <Content {...props} onClose={() => handleClose(id)} />}
                </Dialog>
            ))}
        </>
    );
};

export default Modal;
