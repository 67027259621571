import React, { useState } from 'react';
import { connect } from 'react-redux';
import CaPanelsTable from './CaPanelsTable';
import { Button, Grid, Menu, MenuItem } from '@mui/material';
import { MainCard } from '../../../components';
import {
    assignCaPanelToOrg,
    unassignCaPanelFromOrg
} from '../../../store/actions/CaPanels';

const TabCaPanels = ({
    selectedOrg,
    caPanels,
    rtdbCaPanels,
    assignCaPanelToOrg,
    unassignCaPanelFromOrg
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpenMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleAssignPanel = panel => {
        if (selectedOrg && selectedOrg.org_id) {
            assignCaPanelToOrg(selectedOrg.org_id, panel.id);
        }
        handleCloseMenu();
    };

    const handleUnassignPanel = panel => {
        if (selectedOrg && selectedOrg.org_id) {
            unassignCaPanelFromOrg(selectedOrg.org_id, panel.id);
        }
    };

    const unassignedCaPanels = caPanels.filter(panel => !panel.org_id);

    return (
        <Grid item xs={12}>
            <MainCard
                title="CA Panels"
                content={false}
                secondary={
                    <>
                        <Button onClick={handleOpenMenu}>Assign Panel</Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleCloseMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            {unassignedCaPanels.length > 0 ? (
                                unassignedCaPanels.map(panel => (
                                    <MenuItem
                                        key={panel.id}
                                        onClick={() => handleAssignPanel(panel)}
                                    >
                                        {panel.panel_name}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>No panels available</MenuItem>
                            )}
                        </Menu>
                    </>
                }
            >
                <CaPanelsTable data={rtdbCaPanels} onUnassign={handleUnassignPanel} />
            </MainCard>
        </Grid>
    );
};

const mapStateToProps = ({ org, caPanel }) => {
    const { selectedOrg } = org;
    const { caPanels, rtdbCaPanels } = caPanel;

    return {
        selectedOrg,
        caPanels,
        rtdbCaPanels: rtdbCaPanels ? Object.values(rtdbCaPanels) : []
    };
};

export default connect(mapStateToProps, { assignCaPanelToOrg, unassignCaPanelFromOrg })(
    TabCaPanels
);
