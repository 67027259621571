import React, { useMemo, Fragment } from 'react';

// Components
import { TablePagination } from 'components/third-party/ReactTable';

// Packages
import {
    Box,
    Stack,
    Table as MaterialTable,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import {
    useFilters,
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable
} from 'react-table';

// Utils
import { renderFilterTypes, DefaultColumnFilter } from 'utils/react-table';

const Table = ({ columns, data, onRowClick }) => {
    const theme = useTheme();

    const filterTypes = useMemo(() => renderFilterTypes, []);
    const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
    const initialState = useMemo(
        () => ({
            filters: [],
            pageIndex: 0,
            pageSize: 10
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState,
            filterTypes
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    );

    const handleRowClick = rowData => {
        if (onRowClick) {
            onRowClick(rowData);
        }
    };

    return (
        <Stack sx={{ height: '100%' }}>
            <Box>
                <MaterialTable {...getTableProps()}>
                    <TableHead sx={{ borderTopWidth: 1 }}>
                        {headerGroups.map(headerGroup => {
                            const { key, ...headerGroupProps } =
                                headerGroup.getHeaderGroupProps();
                            return (
                                <TableRow key={key} {...headerGroupProps}>
                                    {headerGroup.headers.map(column => {
                                        const { key, ...columnProps } =
                                            column.getHeaderProps([
                                                { className: column.className },
                                                column.getSortByToggleProps()
                                            ]);
                                        return (
                                            <TableCell
                                                key={key}
                                                {...columnProps}
                                                sx={{ width: 'auto' }}
                                            >
                                                {column.render('Header')}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {data && data.length === 0 ? (
                            <TableRow colSpan="12">
                                <TableCell>
                                    <Typography
                                        variant="h5"
                                        component="span"
                                        color="text.secondary"
                                        sx={{
                                            textTransform: 'capitalize',
                                            width: '100%'
                                        }}
                                    >
                                        {`No data available`}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {page.map(row => {
                                    prepareRow(row);
                                    const { key, ...rowProps } = row.getRowProps();
                                    return (
                                        <Fragment key={key}>
                                            <TableRow
                                                {...rowProps}
                                                onClick={() =>
                                                    handleRowClick(row.original)
                                                }
                                                sx={{
                                                    cursor: 'pointer',
                                                    textTransform: 'capitalize',
                                                    bgcolor: row.isSelected
                                                        ? alpha(
                                                              theme.palette.primary
                                                                  .lighter,
                                                              0.35
                                                          )
                                                        : 'inherit'
                                                }}
                                            >
                                                {row.cells.map(cell => {
                                                    const { key, ...cellProps } =
                                                        cell.getCellProps([
                                                            {
                                                                className:
                                                                    cell.column.className
                                                            }
                                                        ]);
                                                    return (
                                                        <TableCell
                                                            key={key}
                                                            {...cellProps}
                                                        >
                                                            {cell.render('Cell')}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        </Fragment>
                                    );
                                })}

                                <TableRow>
                                    <TableCell sx={{ p: 2 }} colSpan={7}>
                                        <TablePagination
                                            gotoPage={gotoPage}
                                            rows={rows}
                                            setPageSize={setPageSize}
                                            pageIndex={pageIndex}
                                            pageSize={pageSize}
                                        />
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </MaterialTable>
            </Box>
        </Stack>
    );
};

export default Table;
