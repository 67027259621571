import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Button, DialogContent, Stack, Typography } from '@mui/material';

// project import
import Avatar from 'components/@extended/Avatar';

// assets
import { LockFilled } from '@ant-design/icons';

const SuspendModal = ({ title, body, onClose, onSuspend, suspended }) => {
    const handleSuspend = () => {
        if (onSuspend) {
            onSuspend();
        }

        onClose();
    };

    return (
        <>
            <DialogContent sx={{ mt: 2, my: 1 }}>
                <Stack alignItems="center" spacing={3.5}>
                    <Avatar
                        color="warning"
                        sx={{ width: 72, height: 72, fontSize: '1.75rem' }}
                    >
                        <LockFilled />
                    </Avatar>
                    <Stack spacing={2}>
                        <Typography variant="h4" align="center">
                            {body}
                        </Typography>
                        <Typography align="center">
                            <Typography
                                variant="subtitle1"
                                component="span"
                                textTransform={'capitalize'}
                            >
                                {' '}
                                &quot;{title}&quot;{' '}
                            </Typography>
                        </Typography>
                    </Stack>

                    <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                        <Button
                            fullWidth
                            onClick={onClose}
                            color="secondary"
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            fullWidth
                            color="warning"
                            variant="contained"
                            onClick={handleSuspend}
                            autoFocus
                        >
                            {suspended ? 'Unsuspend' : 'Suspend'}
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </>
    );
};

SuspendModal.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

export default SuspendModal;
