import {
    all,
    call,
    cancel,
    cancelled,
    fork,
    put,
    race,
    select,
    take,
    takeLatest
} from 'redux-saga/effects';

import {
    ADD_ORG_PROPERITY,
    DELETE_PROPERITY,
    GET_ORG_PROPERITES_BY_SHORT_CODE,
    GET_ORG_PROPERITES_COLLECTION,
    GET_PROPERTY_BY_ID,
    IMPORT_ORG_PROPERITES,
    LOGOUT_USER,
    SET_ACTIVE_USER_ORG,
    SET_ACTIVE_USER_PROPERTY,
    UPDATE_ORG_PROPERTY
} from '../actions/types';

import { eventChannel } from 'redux-saga';

import {
    deletePropertyFailure,
    deletePropertySuccess,
    getOrgPropertiesByShortCodeFailure,
    getOrgPropertiesByShortCodeSuccess,
    getOrgPropertiesCollectionFailure,
    getOrgPropertiesCollectionSuccess,
    getPropertyByIdCollectionFailure,
    getPropertyByIdCollectionSuccess,
    importOrgPropertiesFailure,
    importOrgPropertiesSuccess,
    setActiveUserPropertyFailure,
    setActiveUserPropertySuccess,
    updateOrgPropertyFailure,
    updateOrgPropertySuccess
} from '../actions/Properties';

import { db, func } from 'config/firebase';
import { httpsCallable } from 'firebase/functions';

import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    setDoc,
    updateDoc,
    where
} from 'firebase/firestore';
import { openSnackbar } from 'store/actions/Snackbar';

import { log } from 'utils/Loggers';
import * as selectors from './Selectors';

const orgPropertiesCollectionRef = collection(db, 'org_properties');
const propertiesCollectionRef = collection(db, 'properties');
const gettingOrgProperties = httpsCallable(func, 'getOrgProperties');

export function* propertyCollectionWatch({ propertyId }) {
    let unsubscribePropertyCollectionData;

    const propertyDocRef = doc(db, 'properties', propertyId);

    const propertyCollectionChannel = eventChannel(emit => {
        unsubscribePropertyCollectionData = onSnapshot(propertyDocRef, propertyDoc => {
            if (propertyDoc.exists()) {
                emit(propertyDoc.data());
            } else {
                emit(new Error('Property not found'));
            }
        });

        return () => unsubscribePropertyCollectionData();
    });

    try {
        while (true) {
            const { userSignOut, propertyCollectionData, error } = yield race({
                userSignOut: take(LOGOUT_USER),
                propertyCollectionData: take(propertyCollectionChannel),
                error: take(propertyCollectionChannel)
            });

            if (userSignOut) {
                propertyCollectionChannel.close();
            } else if (error) {
                yield put(
                    openSnackbar({
                        open: true,
                        message: `Error: ${error.message}`,
                        variant: 'alert',
                        alert: {
                            color: 'error',
                            variant: 'outlined'
                        },
                        close: false
                    })
                );
                yield put(getPropertyByIdCollectionFailure(error));
                yield put(setActiveUserPropertyFailure(error));
            } else {
                yield put(getPropertyByIdCollectionSuccess(propertyCollectionData));
                yield put(setActiveUserPropertySuccess(propertyCollectionData));
            }
        }
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: `Error: ${error.message}`,
                variant: 'alert',
                alert: {
                    color: 'error',
                    variant: 'outlined'
                },
                close: false
            })
        );
        yield put(getPropertyByIdCollectionFailure(error));
    } finally {
        unsubscribePropertyCollectionData();
        if (yield cancelled()) {
            propertyCollectionChannel.close();
            unsubscribePropertyCollectionData();
        }
    }
}

function createOrgPropertiesChannel(orgId) {
    return eventChannel(emit => {
        const unsubscribe = onSnapshot(
            doc(orgPropertiesCollectionRef, orgId),
            docSnap => {
                if (docSnap.exists()) {
                    emit(getOrgPropertiesCollectionSuccess(docSnap.data()));
                } else {
                    emit(
                        getOrgPropertiesCollectionFailure(new Error('No such document'))
                    );
                }
            },
            error => emit(getOrgPropertiesCollectionFailure(error))
        );

        return unsubscribe;
    });
}

export function* getAllOrgProperties({ payload }) {
    const orgId = payload.org_id;
    const channel = yield call(createOrgPropertiesChannel, orgId);
    const task = yield fork(function* () {
        try {
            while (true) {
                const action = yield take(channel);
                yield put(action);
            }
        } finally {
            if (yield cancelled()) {
                channel.close();
            }
        }
    });

    yield take([LOGOUT_USER]);
    yield cancel(task);
}

const validatePropertyImportData = csvData => {
    const requiredFields = ['status', 'address_1'];
    const validationResults = csvData.map(entry => {
        const fieldErrors = requiredFields
            .filter(
                field => !entry[field] || entry[field] === null || entry[field] === 'null'
            )
            .map(field => `Field ${field} is either missing or should not be null.`);

        if (
            entry.status &&
            !['active', 'inactive'].includes(entry.status.toLowerCase())
        ) {
            fieldErrors.push(`Field 'status' must be either 'active' or 'inactive'.`);
        }

        return {
            entry,
            success: fieldErrors.length === 0,
            reason: fieldErrors.length > 0 ? fieldErrors.join(' ') : null
        };
    });

    return validationResults;
};

export function* addNewProperty({ payload }) {
    const { property, orgId } = payload;

    try {
        const propertyRef = collection(db, 'properties');
        const existingPropertyQuery = query(
            propertyRef,
            where('address_1', '==', property.address_1)
        );
        const existingPropertySnapshot = yield call(getDocs, existingPropertyQuery);

        if (!existingPropertySnapshot.empty) {
            yield put(
                openSnackbar({
                    open: true,
                    message: `Error: Property with this address already exists`,
                    variant: 'alert',
                    alert: {
                        color: 'error',
                        variant: 'outlined'
                    },
                    close: false
                })
            );
            return;
        }

        const propertyDocRef = doc(propertyRef, property.id);

        const addressParts = [
            property.address_1,
            property.address_2,
            property.city,
            property.state
        ]
            .filter(part => part)
            .join(' ');
        const addressString = addressParts.trim();
        const propertyToAdd = {
            id: property.id,
            address_1: property.address_1,
            address_2: property.address_2 || null,
            city: property.city || null,
            state: property.state || null,
            zip: property.zip || null,
            org_id: orgId,
            status: property.status,
            longitude: property.longitude || null,
            latitude: property.latitude || null,
            full_address: addressString,
            nested: property.nested
        };

        try {
            yield call(setDoc, propertyDocRef, propertyToAdd);

            const orgPropertiesRef = doc(db, 'org_properties', orgId);
            const orgPropertiesDoc = yield call(getDoc, orgPropertiesRef);
            let orgPropertiesData = {};

            if (orgPropertiesDoc.exists()) {
                orgPropertiesData = orgPropertiesDoc.data();
            }

            orgPropertiesData[property.id] = {
                id: property.id,
                address: propertyToAdd.full_address,
                status: propertyToAdd.status || null
            };

            yield call(setDoc, orgPropertiesRef, orgPropertiesData);

            yield put(
                openSnackbar({
                    open: true,
                    message: `Added property: <strong>${property.address_1}</strong>`,
                    variant: 'alert',
                    alert: {
                        color: 'success',
                        variant: 'outlined'
                    },
                    close: false
                })
            );
        } catch (error) {
            yield put(
                openSnackbar({
                    open: true,
                    message: `Error: ${error.message}`,
                    variant: 'alert',
                    alert: {
                        color: 'error',
                        variant: 'outlined'
                    },
                    close: false
                })
            );
        }
    } catch (err) {
        yield put(
            openSnackbar({
                open: true,
                message: `Error: ${err.message}`,
                variant: 'alert',
                alert: {
                    color: 'error',
                    variant: 'outlined'
                },
                close: false
            })
        );
    }
}

function* updateProperty({ payload }) {
    const { id, ...property } = payload;
    try {
        const propertyDocRef = doc(db, 'properties', id);

        const addressParts = [
            property.address_1,
            property.address_2,
            property.city,
            property.state
        ]
            .filter(part => part)
            .join(' ');

        const updatedProperty = {
            id,
            address_1: property.address_1,
            address_2: property.address_2 || null,
            city: property.city || null,
            state: property.state || null,
            zip: property.zip || null,
            org_id: property.org_id,
            status: property.status,
            longitude: property.longitude || null,
            latitude: property.latitude || null,
            full_address: addressParts.trim()
        };

        yield call(updateDoc, propertyDocRef, updatedProperty);

        const orgId = updatedProperty.org_id;
        const orgPropertiesRef = doc(db, 'org_properties', orgId);
        const orgPropertiesDoc = yield call(getDoc, orgPropertiesRef);

        if (orgPropertiesDoc.exists()) {
            const orgPropertiesData = orgPropertiesDoc.data();
            if (orgPropertiesData[id]) {
                orgPropertiesData[id] = {
                    id,
                    address: updatedProperty.full_address,
                    status: updatedProperty.status || null
                };
                yield call(setDoc, orgPropertiesRef, orgPropertiesData);
            } else {
                throw new Error('Property not found in org properties');
            }
        } else {
            throw new Error('Organization not found in org properties');
        }

        yield put(updateOrgPropertySuccess({ id, ...updatedProperty }));

        yield put(
            openSnackbar({
                open: true,
                message: `Property with ID ${id} updated successfully.`,
                variant: 'alert',
                alert: {
                    color: 'success',
                    variant: 'outlined'
                },
                close: false
            })
        );
    } catch (error) {
        yield put(updateOrgPropertyFailure(error.message));

        yield put(
            openSnackbar({
                open: true,
                message: `Failed to update property: ${error.message}`,
                variant: 'alert',
                alert: {
                    color: 'error',
                    variant: 'outlined'
                },
                close: false
            })
        );
    }
}

export function* importOrgProperties({ payload }) {
    const { properties, orgId } = payload;

    try {
        const validationResults = validatePropertyImportData(properties);

        const rawResults = yield call(
            addPropertiesToCollection,
            validationResults,
            orgId
        );
        const results = rawResults.map(({ property, success, reason }) => ({
            name: property.address_1,
            success,
            reason,
            property
        }));

        const successfulUploads = results.filter(result => result.success);

        if (successfulUploads.length > 0) {
            const orgProperties = successfulUploads.map(({ property }) => property);
            yield call(addOrgPropertiesToOrgPropertiesCollection, orgProperties, orgId);
            yield put(importOrgPropertiesSuccess(results));
        } else {
            yield put(importOrgPropertiesFailure(results));
        }
    } catch (err) {
        yield put(importOrgPropertiesFailure({ success: false, reason: err.message }));
    }
}

function* addOrgPropertiesToOrgPropertiesCollection(addedProperties, orgId) {
    const orgPropertiesRef = doc(db, 'org_properties', orgId);
    const orgPropertiesDoc = yield call(getDoc, orgPropertiesRef);

    if (orgPropertiesDoc.exists()) {
        const existingOrgProperties = orgPropertiesDoc.data();
        for (const property of addedProperties) {
            existingOrgProperties[property.id] = {
                id: property.id,
                address: property.full_address,
                status: property.status || null
            };
        }
        delete existingOrgProperties.undefined;

        yield call(setDoc, orgPropertiesRef, existingOrgProperties);
    } else {
        const newOrgProperties = {};

        for (const property of addedProperties) {
            newOrgProperties[property.id] = {
                id: property.id,
                address: property.full_address,
                status: property.status || null
            };
        }

        yield call(setDoc, orgPropertiesRef, newOrgProperties);
    }
}

function* addPropertiesToCollection(properties, orgId) {
    const results = yield all(
        properties.map(function* ({ entry, success, reason }) {
            if (!success) {
                return {
                    property: entry,
                    success,
                    reason
                };
            }

            const propertyRef = collection(db, 'properties');
            const existingPropertyQuery = query(
                propertyRef,
                where('address_1', '==', entry.address_1)
            );
            const existingPropertySnapshot = yield call(getDocs, existingPropertyQuery);

            if (!existingPropertySnapshot.empty) {
                return {
                    property: entry,
                    success: false,
                    reason: 'Property with this address already exists'
                };
            }

            const nestedOrgs = ['jesvuRjQHFGGB80pkiSB'];
            const isNested = orgId && nestedOrgs.includes(orgId);
            const nested = {
                panels: ['qFeVxwTyW6fBVWQKRaMJTyENgYi2', 'kDeh6xB5sFPErzktwkCFmmwmOty1'],
                final: 'kDeh6xB5sFPErzktwkCFmmwmOty1'
            };

            const propertyDocRef = doc(propertyRef, entry.id);

            const addressParts = [
                entry.address_1,
                entry.address_2,
                entry.city,
                entry.state
            ]
                .filter(part => part)
                .join(' ');

            const addressString = addressParts.trim();
            const propertyToAdd = {
                id: entry.id,
                address_1: entry.address_1,
                address_2: entry?.address_2 || null,
                city: entry?.city || null,
                state: entry?.state || null,
                zip: entry?.zip || null,
                org_id: orgId,
                status: entry.status,
                longitude: entry?.longitude || null,
                latitude: entry?.latitude || null,
                full_address: addressString,
                nested: isNested ? nested : null
            };

            try {
                yield call(setDoc, propertyDocRef, propertyToAdd);
                return {
                    property: propertyToAdd,
                    success: true,
                    reason: null
                };
            } catch (error) {
                return {
                    property: entry,
                    success: false,
                    reason: `Failed to upload property: ${error.message}`
                };
            }
        })
    );

    return results;
}

export function* removeOrgProperty({ payload }) {
    try {
        const { propertyId, orgId } = payload;

        yield call(deleteDoc, doc(propertiesCollectionRef, propertyId));

        const orgPropertiesRef = doc(db, 'org_properties', orgId);
        const orgPropertiesDoc = yield call(getDoc, orgPropertiesRef);

        if (orgPropertiesDoc.exists()) {
            const existingOrgProperties = orgPropertiesDoc.data();
            delete existingOrgProperties[propertyId];

            yield call(setDoc, orgPropertiesRef, existingOrgProperties);
        }

        yield put(deletePropertySuccess(propertyId));

        yield put(
            openSnackbar({
                open: true,
                message: `Property with ID ${propertyId} removed successfully.`,
                variant: 'alert',
                alert: {
                    color: 'success',
                    variant: 'outlined'
                },
                close: false
            })
        );
    } catch (err) {
        yield put(deletePropertyFailure(err));

        yield put(
            openSnackbar({
                open: true,
                message: `Error: ${err}`,
                variant: 'alert',
                alert: {
                    color: 'error',
                    variant: 'outlined'
                },
                close: false
            })
        );
    }
}

export function* getPropertyById(propertyId) {
    try {
        const propertyDocRef = doc(db, 'properties', propertyId);
        const propertyDoc = yield call(getDoc, propertyDocRef);

        if (propertyDoc.exists()) {
            return propertyDoc.data();
        }
    } catch (err) {
        log('Org Error: getting property by id (FS)', {
            error: err,
            propertyId
        });
    }
}

export function* getPropertyByIdCollection({ propertyId }) {
    try {
        const propertyDocRef = doc(db, 'properties', propertyId);
        const propertyDoc = yield call(getDoc, propertyDocRef);

        if (propertyDoc.exists()) {
            yield put(getPropertyByIdCollectionSuccess(propertyDoc.data()));
        } else {
            throw new Error('Property not found');
        }
    } catch (err) {
        yield put(
            openSnackbar({
                open: true,
                message: `Error: ${err}`,
                variant: 'alert',
                alert: {
                    color: 'error',
                    variant: 'outlined'
                },
                close: false
            })
        );
        yield put(getPropertyByIdCollectionFailure(err));
    }
}

function* getOrgPropertiesByShortCode({ payload }) {
    try {
        const { data } = yield call(gettingOrgProperties, {
            shortCode: payload
        });

        if (data?.properties) {
            yield put(getOrgPropertiesByShortCodeSuccess(data.properties));
        } else {
            throw new Error('Properties not found');
        }
    } catch (err) {
        yield put(
            openSnackbar({
                open: true,
                message: `Error: ${err}`,
                variant: 'alert',
                alert: {
                    color: 'error',
                    variant: 'outlined'
                },
                close: false
            })
        );
        yield put(getOrgPropertiesByShortCodeFailure(err));
        log(
            'Registration Error: getting properties for registration form by org shortCode (CF)',
            {
                error: err,
                shortCode: payload
            }
        );
    }
}

export const settingActivePropertyRequest = async ({ active_property_id, userId }) => {
    try {
        const userDocRef = doc(db, 'users', userId);
        await updateDoc(userDocRef, { active_property_id });
        return { res: true };
    } catch (error) {
        return { res: false, error };
    }
};

export function* settingActiveProperty({ payload }) {
    const { id } = payload;
    const userData = yield select(selectors._userData);
    const active_property_id = id;
    const userId = userData.uid;

    const { res, error } = yield call(() =>
        settingActivePropertyRequest({ active_property_id, userId })
    );

    if (res) {
        yield put(setActiveUserPropertySuccess(payload));

        yield put(
            openSnackbar({
                open: true,
                message: `Property set successfully.`,
                variant: 'success',
                autoHideDuration: 3000
            })
        );
    } else {
        yield put(setActiveUserPropertyFailure(error));

        yield put(
            openSnackbar({
                open: true,
                message: `Failed: Property was not set successfully.`,
                variant: 'error',
                autoHideDuration: 3000
            })
        );
    }
}

///////////// Action Creators For Root Saga //////////////////

export function* getOrgPropertiesCollection() {
    yield takeLatest(SET_ACTIVE_USER_ORG, getAllOrgProperties);
}

export function* getOrgProperties() {
    yield takeLatest(GET_ORG_PROPERITES_COLLECTION, getAllOrgProperties);
}

export function* getRegistrationOrgProperties() {
    yield takeLatest(GET_ORG_PROPERITES_BY_SHORT_CODE, getOrgPropertiesByShortCode);
}

export function* getProperty() {
    yield takeLatest(GET_PROPERTY_BY_ID, propertyCollectionWatch);
}

export function* importOrgPropertiesList() {
    yield takeLatest(IMPORT_ORG_PROPERITES, importOrgProperties);
}

export function* addProperty() {
    yield takeLatest(ADD_ORG_PROPERITY, addNewProperty);
}

export function* watchUpdateProperty() {
    yield takeLatest(UPDATE_ORG_PROPERTY, updateProperty);
}

export function* deleteProperty() {
    yield takeLatest(DELETE_PROPERITY, removeOrgProperty);
}

export function* watchSettingActiveProperty() {
    yield takeLatest(SET_ACTIVE_USER_PROPERTY, settingActiveProperty);
}

export default function* rootSaga() {
    yield all([
        fork(getOrgPropertiesCollection),
        fork(importOrgPropertiesList),
        fork(getProperty),
        fork(addProperty),
        fork(watchUpdateProperty),
        fork(deleteProperty),
        fork(getRegistrationOrgProperties),
        fork(getOrgProperties),
        fork(watchSettingActiveProperty)
    ]);
}
