import {
    GET_CA_PANELS_SUCCESS,
    GET_CA_PANELS_FAILURE,
    GET_RTDB_CA_PANELS_SUCCESS,
    GET_RTDB_CA_PANELS_FAILURE,
    ASSIGN_CA_PANEL_TO_ORG,
    ASSIGN_CA_PANEL_TO_ORG_SUCCESS,
    ASSIGN_CA_PANEL_TO_ORG_FAILURE,
    UNASSIGN_CA_PANEL_FROM_ORG,
    UNASSIGN_CA_PANEL_FROM_ORG_SUCCESS,
    UNASSIGN_CA_PANEL_FROM_ORG_FAILURE
} from './types';

export const getCaPanelsSuccess = caPanels => ({
    type: GET_CA_PANELS_SUCCESS,
    payload: caPanels
});

export const getCaPanelsFailure = error => ({
    type: GET_CA_PANELS_FAILURE,
    payload: error
});

export const getRtdbCaPanelsSuccess = caPanels => ({
    type: GET_RTDB_CA_PANELS_SUCCESS,
    payload: caPanels
});

export const getRtdbCaPanelsFailure = error => ({
    type: GET_RTDB_CA_PANELS_FAILURE,
    payload: error
});

export const assignCaPanelToOrg = (orgId, caPanelId) => ({
    type: ASSIGN_CA_PANEL_TO_ORG,
    payload: { orgId, caPanelId }
});

export const assignCaPanelToOrgSuccess = (caPanelId, orgId) => ({
    type: ASSIGN_CA_PANEL_TO_ORG_SUCCESS,
    payload: { caPanelId, orgId }
});

export const assignCaPanelToOrgFailure = error => ({
    type: ASSIGN_CA_PANEL_TO_ORG_FAILURE,
    payload: error
});

export const unassignCaPanelFromOrg = (orgId, caPanelId) => ({
    type: UNASSIGN_CA_PANEL_FROM_ORG,
    payload: { caPanelId, orgId }
});

export const unassignCaPanelFromOrgSuccess = caPanelId => ({
    type: UNASSIGN_CA_PANEL_FROM_ORG_SUCCESS,
    payload: { caPanelId }
});

export const unassignCaPanelFromOrgFailure = error => ({
    type: UNASSIGN_CA_PANEL_FROM_ORG_FAILURE,
    payload: error
});
