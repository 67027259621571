/**
 * Helper function to generate a formatted plate string.
 * Format: {vehicleTag}{vehicleCountry}/{vehicleState}
 *
 * @param {Object} vehicle - Vehicle object
 * @param {string} vehicle.tag - The vehicle's tag (plate number)
 * @param {string} vehicle.country - The vehicle's country
 * @param {string} vehicle.state - The vehicle's state
 *
 * @returns {string} - Formatted vehicle plate string.
 */
export const createVehiclePlate = vehicle => {
    const { tag, country, state } = vehicle;

    return `${tag}${country}/${state}`.toUpperCase();
};

/**
 * Helper function to generate a formatted plate string for Realtime Database.
 * Format: {vehicleTag}{vehicleCountry}{vehicleState} (no slashes).
 *
 * @param {Object} vehicle - Vehicle object
 * @param {string} vehicle.tag - The vehicle's tag (plate number)
 * @param {string} vehicle.country - The vehicle's country
 * @param {string} vehicle.state - The vehicle's state
 *
 * @returns {string} - Formatted vehicle plate string for database.
 */
export const createVehiclePlateForRealtimeDatabase = vehicle => {
    const { tag, country, state } = vehicle;

    return `${tag}${country}${state}`.toUpperCase();
};

export const isPlateUnique = (plate, orgVehiclePlates) => {
    return !orgVehiclePlates.some(vehiclePlate => vehiclePlate === plate);
};
