import {
    ADD_VEHICLE_SUCCESS,
    DELETE_VEHICLE_SUCCESS,
    RESET_VEHICLE_OPERATION_STATE,
    UPDATE_VEHICLE_SUCCESS,
    VEHICLE_OPERATION_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    operationSuccess: false
};

const VehicleReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_VEHICLE_SUCCESS:
        case UPDATE_VEHICLE_SUCCESS:
        case DELETE_VEHICLE_SUCCESS:
            return {
                ...state,
                operationSuccess: true
            };
        case VEHICLE_OPERATION_FAILURE:
            return {
                ...state,
                operationSuccess: false
            };
        case RESET_VEHICLE_OPERATION_STATE:
            return {
                ...state,
                operationSuccess: false
            };
        default:
            return state;
    }
};

export default VehicleReducer;
