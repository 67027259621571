import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    modals: []
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        showModal: (state, action) => {
            state.modals.push({
                id: Date.now(),
                modalContainerProps: action.payload.modalContainerProps || {},
                content: action.payload.content,
                props: action.payload.props || {}
            });
        },
        hideModal: (state, action) => {
            state.modals = state.modals.filter(modal => modal.id !== action.payload.id);
        },
        hideAllModals: state => {
            state.modals = [];
        }
    }
});

export const { showModal, hideModal, hideAllModals } = modalSlice.actions;
export default modalSlice.reducer;
