import React, { useMemo, useState } from 'react';
import Table from '../../../components/common/Table';
import { Chip, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

const CaPanelsTable = ({ data, onUnassign }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedPanel, setSelectedPanel] = useState(null);

    const handleMenuClick = (event, panel) => {
        setAnchorEl(event.currentTarget);
        setSelectedPanel(panel);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedPanel(null);
    };

    const handleUnassignPanel = () => {
        onUnassign(selectedPanel);
        handleMenuClose();
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'panel_name'
            },
            {
                Header: 'CA ID',
                accessor: 'panel_ca_id'
            },
            {
                Header: 'Comport',
                accessor: 'comport'
            },
            {
                Header: 'Panel Number',
                accessor: 'panel_num'
            },
            {
                Header: 'Panel Type',
                accessor: 'panel_type'
            },
            {
                Header: 'Status',
                accessor: 'enable',
                Cell: ({ value }) =>
                    value ? (
                        <Chip size="small" color="success" label="Active" />
                    ) : (
                        <Chip size="small" color="error" label="Inactive" />
                    )
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                className: 'cell-center',
                Cell: ({ row }) => (
                    <IconButton onClick={e => handleMenuClick(e, row.original)}>
                        <MoreHoriz />
                    </IconButton>
                )
            }
        ],
        [onUnassign]
    );

    return (
        <>
            <Table columns={columns} data={data} />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <MenuItem onClick={handleUnassignPanel}>Unassign</MenuItem>
            </Menu>
        </>
    );
};

export default CaPanelsTable;
