import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';

import {
    countries,
    errorMessage,
    nameRegExp,
    streetAddressRegExp
} from 'utils/constants';

import {
    Button,
    Grid,
    InputLabel,
    Select,
    Stack,
    TextField,
    MenuItem,
    FormControl,
    InputAdornment,
    IconButton,
    Autocomplete
} from '@mui/material';
import { connect } from 'react-redux';
import { addReservation } from 'store/actions/Reservations';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { openSnackbar } from 'store/actions/Snackbar';

import 'react-widgets/scss/styles.scss';
import AnimateButton from 'components/@extended/AnimateButton';
import { AddReservationSuccess, Loader } from 'components';
import { tsFromJsDate } from 'utils/Helpers';
import { serverTimestamp } from '@firebase/firestore';
import { CopyOutlined } from '@ant-design/icons';

const renterLink = 'https://sagesystems.io/confirm-reservation';

const ReservationForm = ({
    adding,
    addingSuccess,
    activeOrg,
    userData,
    addReservation,
    openSnackbar,
    reservation,
    orgProperties
}) => {
    const handleCopyToClipboard = async text => {
        await navigator.clipboard.writeText(text);
        openSnackbar({
            open: true,
            message: `Reservation link copied to clipboard!`,
            variant: 'alert',
            alert: {
                color: 'success',
                variant: 'outlined'
            },
            close: false
        });
    };

    const findOrgPropertiesIdByAddress = address => {
        for (const id in orgProperties) {
            if (Object.prototype.hasOwnProperty.call(orgProperties, id)) {
                const property = orgProperties[id];
                if (property.address === address) {
                    return id;
                }
            }
        }
        return null;
    };

    const submit = values => {
        const phone = values.phone?.number
            ? {
                  number: Number.parseInt(values.phone.number),
                  code: Number.parseInt(values.phone.code),
                  country: values.phone.country
              }
            : null;

        const reservationData = {
            access_begins: tsFromJsDate(values.check_in_date),
            access_created: null,
            access_days: null,
            access_end_time: null,
            access_expires: tsFromJsDate(values.check_out_date),
            access_start_time: null,
            active: true,
            property_id: findOrgPropertiesIdByAddress(values.address),
            address: {
                address_1: values.address_1 || null,
                address_2: values.address_2 || null,
                city: null,
                latitude: null,
                longitude: null,
                state: null,
                zip: null
            },
            check_in_date: values.check_in_date.toDateString(),
            check_in_time: values.check_in_time
                ? {
                      hours: values.check_in_time.getHours(),
                      minutes: values.check_in_time.getMinutes()
                  }
                : { hours: 0, minutes: 0 },
            check_out_date: values.check_out_date.toDateString(),
            check_out_time: values.check_out_time
                ? {
                      hours: values.check_out_time.getHours(),
                      minutes: values.check_out_time.getMinutes()
                  }
                : { hours: 23, minutes: 59 },
            company_name: null,
            confirmation: null,
            consumer_id: null,
            created_at: serverTimestamp(),
            image: null,
            invite_id: null,
            invite_status: null,
            invited_at: null,
            creator_first_name: userData.first_name,
            creator_id: userData.uid,
            creator_last_name: userData.last_name,
            email: values.email || null,
            event_id: null,
            favorite: false,
            first_name: values.first_name,
            invite_code: null,
            last_name: values.last_name,
            org_id: activeOrg.org_id,
            org_name: activeOrg.org_name,
            parties: null,
            phone,
            phone_number: values.phone?.number
                ? `${values.phone.code}${values.phone.number}`
                : null,
            role: 'tenant',
            suspended: false,
            validated: false,
            client_reservation_id: values?.client_reservation_id,
            key_id: reservation?.key_id || null,
            reservation_id: reservation?.reservation_id || null
        };
        addReservation({ data: reservationData });
    };

    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.firstName.valid)
            .required(errorMessage.firstName.required),
        last_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.lastName.valid)
            .required(errorMessage.lastName.required),
        check_in_date: Yup.string().trim().required(errorMessage.fromDate.required),
        street_address: Yup.string().trim(),
        unit: Yup.string().trim(),
        email: Yup.string().trim().lowercase().email(errorMessage.email.valid),
        address: Yup.string()
            .trim()
            .required(errorMessage.address.required)
            .matches(streetAddressRegExp, errorMessage.address.valid),
        phone: Yup.object().shape({
            country: Yup.string().trim(),
            number: Yup.string().trim(),
            code: Yup.string().trim()
        }),
        client_reservation_id: Yup.string().trim()
    });

    const validateFields = ({
        phone: { code, number, country },
        check_in_date,
        check_out_date
    }) => {
        const phoneSchema = Yup.string().phone(country);
        const isValidPhone = number ? phoneSchema.isValidSync(code + number) : true;
        const isValidCheckOutDate = check_out_date > check_in_date; // Check-out date must be after check-in date

        const errors = {};
        if (!isValidPhone) {
            errors.phone = { number: 'Invalid number' };
        }
        if (!isValidCheckOutDate) {
            errors.check_out_date = 'Check-out date must be after check-in date';
        }

        return Object.keys(errors).length === 0 ? undefined : errors;
    };

    function formatDate(dateString, timeObject) {
        const months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ];
        const dateParts = dateString.split(' ');
        const monthIndex = months.indexOf(dateParts[1]);
        const year = Number.parseInt(dateParts[3]);
        const day = Number.parseInt(dateParts[2]);

        const hours = timeObject.hours;
        const minutes = timeObject.minutes;

        return new Date(year, monthIndex, day, hours, minutes);
    }

    function getAddressForProperty(propertyId, orgProperties) {
        const property = orgProperties[propertyId];
        return property ? property.address : '';
    }

    const initialValues = {
        first_name: reservation?.first_name || '',
        client_reservation_id: reservation?.client_reservation_id || '',
        last_name: reservation?.last_name || '',
        check_in_date: reservation?.check_in_date
            ? formatDate(reservation.check_in_date, reservation.check_in_time)
            : null,
        check_in_time: reservation?.check_in_time
            ? formatDate(reservation.check_in_date, reservation.check_in_time)
            : null,
        check_out_date: reservation?.check_out_date
            ? formatDate(reservation.check_out_date, reservation.check_out_time)
            : null,
        check_out_time: reservation?.check_out_time
            ? formatDate(reservation.check_out_date, reservation.check_out_time)
            : null,
        property_id: reservation?.property_id || '',
        email: reservation?.email || '',
        phone: {
            number: reservation?.phone?.number.toString() || '',
            code: reservation?.phone?.code.toString() || '1',
            country: reservation?.phone?.country || 'US'
        },
        address: reservation?.property_id
            ? getAddressForProperty(reservation.property_id, orgProperties)
            : ''
    };

    if (addingSuccess) {
        return <AddReservationSuccess />;
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validate={validateFields}
            validationSchema={validationSchema}
            validateOnChange
            enableReinitialize
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                touched,
                values
            }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container spacing={3}>
                        {adding && <Loader />}
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={0.5}>
                                <InputLabel>Client Reservation ID</InputLabel>
                                <TextField
                                    required
                                    id="firstNameBasic"
                                    name="client_reservation_id"
                                    placeholder="Client Reservation ID"
                                    fullWidth
                                    autoComplete="given-name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.client_reservation_id &&
                                        !!errors.client_reservation_id
                                    }
                                    helperText={
                                        touched.client_reservation_id &&
                                        errors.client_reservation_id
                                    }
                                    value={values.client_reservation_id}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={0.5}>
                                <InputLabel>Address</InputLabel>
                                <Autocomplete
                                    id="address"
                                    name="address"
                                    options={Object.values(orgProperties || {})}
                                    getOptionLabel={option => option.address}
                                    onChange={(_, newValue) => {
                                        if (newValue) {
                                            handleChange({
                                                target: {
                                                    name: 'address',
                                                    value: newValue.address
                                                }
                                            });
                                            handleChange({
                                                target: {
                                                    name: 'property_id',
                                                    value: newValue.id
                                                }
                                            });
                                        }
                                    }}
                                    value={orgProperties[values.property_id] || null}
                                    onBlur={handleBlur}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            required
                                            placeholder="1234 Main St"
                                            fullWidth
                                            autoComplete="shipping address-line1"
                                            error={touched.address && !!errors.address}
                                            helperText={touched.address && errors.address}
                                            value={values.address}
                                        />
                                    )}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={0.5}>
                                <InputLabel>First Name</InputLabel>
                                <TextField
                                    required
                                    id="firstNameBasic"
                                    name="first_name"
                                    placeholder="First Name"
                                    fullWidth
                                    autoComplete="given-name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.first_name && !!errors.first_name}
                                    helperText={touched.first_name && errors.first_name}
                                    value={values.first_name}
                                    inputProps={{
                                        style: { textTransform: 'capitalize' }
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={0.5}>
                                <InputLabel>Last Name</InputLabel>
                                <TextField
                                    required
                                    id="lastNameBasic"
                                    name="last_name"
                                    placeholder="Last name"
                                    fullWidth
                                    autoComplete="family-name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.last_name && !!errors.last_name}
                                    helperText={touched.last_name && errors.last_name}
                                    value={values.last_name}
                                    inputProps={{
                                        style: { textTransform: 'capitalize' }
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={0.5}>
                                <InputLabel>Check-in Date</InputLabel>
                                <FormControl sx={{ width: '100%' }}>
                                    <DatePicker
                                        error={Boolean(
                                            touched.check_in_date && errors.check_in_date
                                        )}
                                        value={values.check_in_date}
                                        onChange={newValue =>
                                            setFieldValue('check_in_date', newValue)
                                        }
                                        minDate={new Date()}
                                        slotProps={{
                                            textField: {
                                                error:
                                                    touched.check_in_date &&
                                                    !!errors.check_in_date,
                                                helperText:
                                                    touched.check_in_date &&
                                                    errors.check_in_date
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={0.5}>
                                <InputLabel>Check-in Time</InputLabel>
                                <FormControl sx={{ width: '100%' }}>
                                    <TimePicker
                                        value={values.check_in_time}
                                        onChange={newValue =>
                                            setFieldValue('check_in_time', newValue)
                                        }
                                    />
                                </FormControl>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={0.5}>
                                <InputLabel>Check-out Date</InputLabel>
                                <FormControl sx={{ width: '100%', borderColor: 'red' }}>
                                    <DatePicker
                                        value={values.check_out_date}
                                        onChange={newValue =>
                                            setFieldValue('check_out_date', newValue)
                                        }
                                        minDate={
                                            values?.check_in_date
                                                ? new Date(
                                                      values.check_in_date.getTime() +
                                                          86400000
                                                  )
                                                : new Date(
                                                      new Date().setDate(
                                                          new Date().getDate() + 1
                                                      )
                                                  )
                                        }
                                        slotProps={{
                                            textField: {
                                                error:
                                                    touched.check_out_date &&
                                                    !!errors.check_out_date,
                                                helperText:
                                                    touched.check_out_date &&
                                                    errors.check_out_date
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={0.5}>
                                <InputLabel>Check-out Time</InputLabel>
                                <FormControl sx={{ width: '100%' }}>
                                    <TimePicker
                                        value={values.check_out_time}
                                        onChange={newValue =>
                                            setFieldValue('check_out_time', newValue)
                                        }
                                    />
                                </FormControl>
                            </Stack>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Stack spacing={0.5}>
                                <InputLabel htmlFor="phone.country">Area Code</InputLabel>
                                <Select
                                    id="areaCodeSelect"
                                    name="phone.country"
                                    onChange={e => {
                                        const selectedCountry = countries.find(
                                            country => country.value === e.target.value
                                        );
                                        if (selectedCountry) {
                                            setFieldValue(
                                                'phone.code',
                                                selectedCountry.phoneCode.toString()
                                            );
                                            setFieldValue(
                                                'phone.country',
                                                selectedCountry.value
                                            );
                                        }
                                    }}
                                    value={values.phone?.country}
                                    label="Area Code"
                                >
                                    {countries.map(country => (
                                        <MenuItem
                                            key={country.value}
                                            value={country.value}
                                        >
                                            {country.flagIcon} +{country.phoneCode}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                        </Grid>
                        <Grid item xs={10} sm={4}>
                            <Stack spacing={0.5}>
                                <InputLabel>Mobile Phone</InputLabel>
                                <TextField
                                    required
                                    id="firstNameBasic"
                                    name="phone.number"
                                    placeholder="Phone"
                                    fullWidth
                                    autoComplete="given-name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.phone?.number && !!errors.phone?.number
                                    }
                                    helperText={
                                        touched.phone?.number && errors.phone?.number
                                    }
                                    value={values.phone?.number}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={0.5}>
                                <InputLabel>Email Address</InputLabel>
                                <TextField
                                    required
                                    id="countryBasic"
                                    name="email"
                                    placeholder="Name"
                                    fullWidth
                                    autoComplete="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.email && !!errors.email}
                                    helperText={touched.email && errors.email}
                                    value={values.email}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={0.5} onClick={handleCopyToClipboard}>
                                <TextField
                                    placeholder="reservation link"
                                    value={renterLink}
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    onClick={handleCopyToClipboard}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleCopyToClipboard}
                                                    edge="end"
                                                >
                                                    <CopyOutlined />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        style: { cursor: 'pointer' }
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-end"
                                flexDirection="row-reverse"
                            >
                                <AnimateButton>
                                    <Button
                                        variant="contained"
                                        onClick={handleSubmit}
                                        sx={{ mt: 3 }}
                                        disabled={adding}
                                    >
                                        Save
                                    </Button>
                                </AnimateButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            )}
        </Formik>
    );
};

const mapStateToProps = ({ reservations, user, properties }) => {
    const { addingReservation, addingReservationSuccess, addingReservationError } =
        reservations;
    const { userData, activeOrg } = user;
    const { orgProperties } = properties;

    return {
        adding: addingReservation,
        addingSuccess: addingReservationSuccess,
        addingError: addingReservationError,
        userData,
        activeOrg,
        orgProperties
    };
};

export default connect(mapStateToProps, {
    addReservation,
    openSnackbar
})(ReservationForm);
