import {
    all,
    fork,
    takeLatest,
    put,
    call,
    race,
    cancelled,
    take
} from 'redux-saga/effects';
import {
    ASSIGN_CA_PANEL_TO_ORG,
    GET_CA_PANELS,
    GET_RTDB_CA_PANELS,
    LOGOUT_USER,
    UNASSIGN_CA_PANEL_FROM_ORG
} from '../actions/types';
import { ref, get, set, child, remove } from 'firebase/database';
import { db, rtdb } from 'config/firebase';
import {
    assignCaPanelToOrgFailure,
    assignCaPanelToOrgSuccess,
    getCaPanelsFailure,
    getCaPanelsSuccess,
    getRtdbCaPanelsFailure,
    getRtdbCaPanelsSuccess,
    unassignCaPanelFromOrgFailure,
    unassignCaPanelFromOrgSuccess
} from '../actions/CaPanels';
import { eventChannel } from 'redux-saga';
import { collection, doc, onSnapshot, query, updateDoc } from 'firebase/firestore';
import { getDoc } from '@firebase/firestore';
import { openSnackbar } from '../actions/Snackbar';

export const getRtdbOrgCaPanelsRef = orgId => ref(rtdb, `orgs/${orgId}/ca_panels`);

function* listenToFirebaseChannel(query, successAction, additionalDataProcessor = null) {
    const channel = eventChannel(emit => {
        const unsubscribe = onSnapshot(query, querySnapshot => {
            const data = [];
            querySnapshot.forEach(doc => {
                data.push({ id: doc.id, ...doc.data() });
            });

            if (additionalDataProcessor) {
                emit(additionalDataProcessor(data));
            } else {
                emit(data);
            }
        });

        return unsubscribe;
    });

    try {
        while (true) {
            const { userSignOut, data } = yield race({
                userSignOut: take(LOGOUT_USER),
                data: take(channel)
            });

            if (userSignOut) {
                channel.close();
                break;
            } else {
                yield put(successAction(data));
            }
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}

export function* listenToCaPanels() {
    try {
        const caPanelsCollectionRef = collection(db, 'ca_panels');
        const queryRef = query(caPanelsCollectionRef);

        yield call(listenToFirebaseChannel, queryRef, getCaPanelsSuccess);
    } catch (error) {
        yield put(getCaPanelsFailure(error.message));
    }
}

export function* getRtdbCaPanels(payload) {
    const { active_org_id } = payload;

    try {
        const caPanelsRef = getRtdbOrgCaPanelsRef(active_org_id);
        const caPanelsSnapshot = yield call(get, caPanelsRef);

        if (caPanelsSnapshot.exists()) {
            const caPanelsData = caPanelsSnapshot.val();
            yield put(getRtdbCaPanelsSuccess(caPanelsData));
        } else {
            yield put(getRtdbCaPanelsFailure('No Ca Panels data found.'));
        }
    } catch (error) {
        yield put(getRtdbCaPanelsFailure(error.message));
    }
}

export function* assignCaPanelToOrg({ payload }) {
    const { orgId, caPanelId } = payload;

    try {
        const caPanelDocRef = doc(db, 'ca_panels', caPanelId);
        const caPanelSnapshot = yield call(getDoc, caPanelDocRef);

        if (caPanelSnapshot.exists()) {
            const caPanelData = caPanelSnapshot.data();

            yield call(updateDoc, caPanelDocRef, { org_id: orgId });

            const orgCaPanelsRef = getRtdbOrgCaPanelsRef(orgId);
            yield call(set, child(orgCaPanelsRef, caPanelId), {
                ...caPanelData,
                org_id: orgId
            });

            yield put(assignCaPanelToOrgSuccess(caPanelId, orgId));

            yield fork(getRtdbCaPanels, { active_org_id: orgId });

            yield put(
                openSnackbar({
                    open: true,
                    message: `CA Panel assigned successfully`,
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    }
                })
            );
        } else {
            throw new Error('CA Panel not found');
        }
    } catch (error) {
        yield put(assignCaPanelToOrgFailure(error.message));

        yield put(
            openSnackbar({
                open: true,
                message: `Failed to assign CA Panel: ${error.message}`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
    }
}

export function* unassignCaPanelFromOrg({ payload }) {
    const { orgId, caPanelId } = payload;

    try {
        const caPanelDocRef = doc(db, 'ca_panels', caPanelId);
        const caPanelSnapshot = yield call(getDoc, caPanelDocRef);

        if (caPanelSnapshot.exists()) {
            yield call(updateDoc, caPanelDocRef, { org_id: null });

            const orgCaPanelsRef = getRtdbOrgCaPanelsRef(orgId);
            yield call(remove, child(orgCaPanelsRef, caPanelId));

            yield put(unassignCaPanelFromOrgSuccess(caPanelId));

            yield fork(getRtdbCaPanels, { active_org_id: orgId });

            yield put(
                openSnackbar({
                    open: true,
                    message: `CA Panel unassigned successfully`,
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    }
                })
            );
        } else {
            throw new Error('CA Panel not found');
        }
    } catch (error) {
        yield put(unassignCaPanelFromOrgFailure(error.message));

        yield put(
            openSnackbar({
                open: true,
                message: `Failed to unassign CA Panel: ${error.message}`,
                variant: 'alert',
                alert: {
                    color: 'error'
                }
            })
        );
    }
}

export function* watchGetCaPanels() {
    yield takeLatest(GET_CA_PANELS, listenToCaPanels);
}

export function* watchGetRtdbCaPanels() {
    yield takeLatest(GET_RTDB_CA_PANELS, getRtdbCaPanels);
}

export function* watchAssignCaPanelToOrg() {
    yield takeLatest(ASSIGN_CA_PANEL_TO_ORG, assignCaPanelToOrg);
}

export function* watchUnassignCaPanelFromOrg() {
    yield takeLatest(UNASSIGN_CA_PANEL_FROM_ORG, unassignCaPanelFromOrg);
}

export default function* caPanelsSaga() {
    yield all([
        fork(watchGetCaPanels),
        fork(watchGetRtdbCaPanels),
        fork(watchAssignCaPanelToOrg),
        fork(watchUnassignCaPanelFromOrg)
    ]);
}
