import {
    GET_REPORT_PROPERTIES,
    GET_REPORT_PROPERTIES_SUCCESS,
    GET_REPORT_PROPERTIES_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    reportProperties: null,
    loading: false,
    error: null
};

const reportsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_REPORT_PROPERTIES:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_REPORT_PROPERTIES_SUCCESS:
            return {
                ...state,
                reportProperties: action.payload,
                loading: false
            };
        case GET_REPORT_PROPERTIES_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
};

export default reportsReducer;
