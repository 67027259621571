import {
    GET_CA_PANELS_SUCCESS,
    GET_CA_PANELS_FAILURE,
    GET_RTDB_CA_PANELS_SUCCESS,
    GET_RTDB_CA_PANELS_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    caPanels: [],
    rtdbCaPanels: {},
    error: null
};

const caPanelsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CA_PANELS_SUCCESS:
            return {
                ...state,
                caPanels: action.payload,
                error: null
            };
        case GET_CA_PANELS_FAILURE:
            return {
                ...state,
                caPanels: null,
                error: action.payload
            };
        case GET_RTDB_CA_PANELS_SUCCESS:
            return {
                ...state,
                rtdbCaPanels: action.payload,
                error: null
            };
        case GET_RTDB_CA_PANELS_FAILURE:
            return {
                ...state,
                rtdbCaPanels: null,
                error: action.payload
            };
        default:
            return state;
    }
};

export default caPanelsReducer;
