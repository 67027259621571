import React, { useMemo } from 'react';

import Table from '../../common/Table';
import { Typography } from '@mui/material';
import { DEVICE_STATUSES } from '../../../utils/constants/statuses';

const DeviceTable = ({ data, onDeviceClick }) => {
    const columns = useMemo(
        () => [
            {
                Header: 'Facility Code',
                accessor: 'facility_code'
            },
            {
                Header: 'Device No.',
                accessor: 'device_number',
                className: 'cell-center'
            },
            {
                Header: 'Device Type',
                accessor: 'device_type',
                className: 'cell-center'
            },
            {
                Header: 'Resident',
                accessor: 'owner_name',
                className: 'cell-center'
            },
            {
                Header: 'Access Groups',
                accessor: 'access_groups',
                className: 'cell-right',
                Cell: ({ value }) => (value ? value.join(', ') : '')
            },
            {
                Header: 'Status',
                accessor: 'status',
                className: 'cell-center',
                Cell: ({ value }) => {
                    const style = {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '80%',
                        backgroundColor: DEVICE_STATUSES[value]?.background,
                        borderRadius: '5px',
                        border: `1px solid ${DEVICE_STATUSES[value]?.border}`,
                        color: DEVICE_STATUSES[value]?.color
                    };

                    return (
                        <div style={style}>
                            <Typography
                                sx={{
                                    fontSize: '13px',
                                    marginX: '10px'
                                }}
                                component="span"
                                variant="body1"
                                textTransform="capitalize"
                            >
                                {value}
                            </Typography>
                        </div>
                    );
                }
            }
        ],
        []
    );

    return <Table columns={columns} data={data} onRowClick={onDeviceClick} />;
};

export default DeviceTable;
