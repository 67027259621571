import {
    GET_REPORT_PROPERTIES,
    GET_REPORT_PROPERTIES_SUCCESS,
    GET_REPORT_PROPERTIES_FAILURE
} from './types';

export const getReportProperties = () => {
    return {
        type: GET_REPORT_PROPERTIES
    };
};

export const getReportPropertiesSuccess = data => {
    return {
        type: GET_REPORT_PROPERTIES_SUCCESS,
        payload: data
    };
};

export const getReportPropertiesFailure = error => {
    return {
        type: GET_REPORT_PROPERTIES_FAILURE,
        payload: error
    };
};
