/* eslint-disable react/no-array-index-key */
// React
import React, { useMemo } from 'react';

// Components
import { TablePagination } from 'components/third-party/ReactTable';

// Packages
import { connect } from 'react-redux';
import { DefaultColumnFilter, renderFilterTypes } from 'utils/react-table';
import { useTable, useFilters, usePagination } from 'react-table';
import {
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Link
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const ReportsTable = ({ data, reportProperties }) => {
    const filterTypes = useMemo(() => renderFilterTypes, []);
    const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
    const initialState = useMemo(() => ({ filters: [], pageIndex: 0, pageSize: 10 }), []);

    const columns = useMemo(
        () => [
            {
                Header: 'Org ID',
                accessor: 'org_id',
                filter: 'fuzzyText'
            },
            {
                Header: 'Org Name',
                accessor: 'org_name',
                filter: 'fuzzyText'
            },
            {
                Header: 'Active Property Count',
                accessor: 'propertyCount',
                filter: 'fuzzyText'
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState,
            filterTypes
        },
        useFilters,
        usePagination
    );
    return (
        <Stack>
            <Table {...getTableProps()}>
                <TableHead sx={{ borderTopWidth: 1 }}>
                    {headerGroups.map((headerGroup, index) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, i) => (
                                <TableCell
                                    {...column.getHeaderProps([
                                        { className: column.className }
                                    ])}
                                    key={i}
                                >
                                    {column.render('Header')}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {headerGroups.map((group, i) => (
                        <TableRow {...group.getHeaderGroupProps()} key={i}>
                            {group.headers.map((column, index) => (
                                <TableCell
                                    {...column.getHeaderProps([
                                        { className: column.className }
                                    ])}
                                    key={index}
                                >
                                    {column.canFilter ? column.render('Filter') : null}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <TableRow
                                hover
                                role="checkbox"
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                tabIndex={-1}
                                {...row.getRowProps()}
                                key={i}
                            >
                                {row.cells.map((cell, index) => (
                                    <TableCell
                                        {...cell.getCellProps([
                                            { className: cell.column.className }
                                        ])}
                                        align={'left'}
                                        key={index}
                                    >
                                        {cell.column.id === 'org_id' ? (
                                            <Link
                                                color="secondary"
                                                component={RouterLink}
                                                to={cell.value}
                                            >
                                                {cell.render('Cell')}
                                            </Link>
                                        ) : (
                                            cell.render('Cell')
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        );
                    })}

                    <TableRow>
                        <TableCell sx={{ p: 2 }} colSpan={7}>
                            <TablePagination
                                gotoPage={gotoPage}
                                rows={rows}
                                setPageSize={setPageSize}
                                pageIndex={pageIndex}
                                pageSize={pageSize}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Stack>
    );
};

const mapStateToProps = ({ org }) => {
    const { orgs } = org;
    return { orgs };
};

export default connect(mapStateToProps, {})(ReportsTable);
