import { all, fork, take, cancelled, takeLatest, race, put } from 'redux-saga/effects';

import { GET_REPORT_PROPERTIES, LOGOUT_USER } from '../actions/types';

import { eventChannel } from 'redux-saga';

import { getReportPropertiesSuccess } from '../actions/Reports';

import { db } from 'config/firebase';

// Loggers
import { log } from '../../utils/Loggers';
import { collection, onSnapshot } from 'firebase/firestore';

const propertiesCollectionRef = collection(db, 'properties');

//////////////// Get Org Properties ////////////////

export function* reportPropertiesCollectionWatch() {
    let unsubscribeReportPropertiesData;
    const reportPropertiesCollectionChannel = eventChannel(emit => {
        unsubscribeReportPropertiesData = onSnapshot(
            propertiesCollectionRef,
            querySnapshot => {
                var properties = [];
                querySnapshot.forEach(doc => {
                    properties.push(doc.data());
                });
                emit(properties);
            }
        );
        return unsubscribeReportPropertiesData;
    });

    try {
        while (true) {
            const { userSignOut, reportPropertiesData } = yield race({
                userSignOut: take(LOGOUT_USER),
                reportPropertiesData: take(reportPropertiesCollectionChannel)
            });

            if (userSignOut) {
                reportPropertiesCollectionChannel.close();
            } else {
                yield put(getReportPropertiesSuccess(reportPropertiesData));
            }
        }
    } catch (error) {
        log('Report Propeties Error: getting properties collection data (FS)', {
            error
        });
    } finally {
        unsubscribeReportPropertiesData();
        if (yield cancelled()) {
            reportPropertiesCollectionChannel.close();
            unsubscribeReportPropertiesData();
        }
    }
}

///////////// Action Creators For Root Saga //////////////////

export function* getReportProperties() {
    yield takeLatest(GET_REPORT_PROPERTIES, reportPropertiesCollectionWatch);
}

export default function* rootSaga() {
    yield all([fork(getReportProperties)]);
}
