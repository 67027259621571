import React from 'react';
// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    UnorderedListOutlined,
    PlusSquareOutlined,
    TableOutlined
} from '@ant-design/icons';

// icons
const icons = {
    UnorderedListOutlined,
    PlusSquareOutlined,
    TableOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const superAdmin = {
    id: 'super_admin',
    title: <FormattedMessage id="superAdmin" />,
    type: 'group',
    children: [
        {
            id: 'organizations',
            title: <FormattedMessage id="organizations" />,
            type: 'item',
            url: '/orgs',
            icon: icons.UnorderedListOutlined
        },
        {
            id: 'createOrg',
            title: <FormattedMessage id="createOrg" />,
            type: 'item',
            url: '/create-org',
            icon: icons.PlusSquareOutlined
        },
        {
            id: 'deviceOperations',
            title: <FormattedMessage id="deviceOperations" />,
            type: 'item',
            url: '/device-operations',
            icon: icons.TableOutlined
        },
        {
            id: 'reports',
            title: <FormattedMessage id="reports" />,
            type: 'item',
            url: '/reports',
            icon: icons.TableOutlined
        }
    ]
};

export default superAdmin;
